<div class="footer">
  <div style="text-align: center">
    <p>Genom att fortsätta bekräftar jag att jag har läst</p>
    <a href="https://24food.se/integritetspolicy" target="_blank"
      >24 Foods Personuppgiftspolicy</a
    >
  </div>
</div>

<style lang="scss">
  .footer {
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .footer p,
  a {
    display: inline;
    margin: 0;
    text-decoration: none;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: #333333;
  }

  a {
    text-decoration: underline;
  }
</style>
