const hasGeolocation = "geolocation" in navigator;
const hasPermissionQuery = "permissions" in navigator && "query" in navigator.permissions;

export async function hasGeoLocationPermission() {
	if (hasPermissionQuery) {
		const result = await navigator.permissions.query({ name: "geolocation" });
		return result.state === "granted";
	}

	return false;
}

export async function geoLocationPermissionListener(
	callbackFn: (permissionState: PermissionState) => void,
) {
	if (hasPermissionQuery) {
		navigator.permissions
			.query({ name: "geolocation" })
			.then((permissionStatus) => {
				callbackFn(permissionStatus.state);
			});
	}
}

export function geoLocationWatcher(
	callbackFn?: (position: GeolocationPosition) => void,
	callbackErrorFn?: (error: GeolocationPositionError, errorMessage: string) => void,
) {
	if (!hasGeolocation) {
		return () => undefined;
	}

	const id = navigator.geolocation.watchPosition(
		(position) => {
			callbackFn?.(position);
		},
		(error) => {
			let errorMessage = "";
			switch (error.code) {
				case 1:
					errorMessage =
						"Vi kan inte hämta din plats eftersom sidan saknar nödvändig behörighet. Kontrollera dina inställningar och försök igen.";
					break;
				case 2:
					errorMessage =
						"Vi kunde inte hämta din platsinformation just nu. Det kan bero på ett tekniskt problem.";
					break;
				case 3:
					errorMessage =
						"Det tog för lång tid att hämta din platsinformation. Försök igen.";
					break;
				default:
					errorMessage =
						"Något gick fel när vi försökte hämta din plats. Försök igen.";
			}
			callbackErrorFn?.(error, errorMessage);
		},
		{
			enableHighAccuracy: true,
		},
	);

	return () => {
		navigator.geolocation.clearWatch(id);
	};
}
