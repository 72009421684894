import { apiBaseUrl } from '../config';

export async function get<T = unknown>(url: string) {
  return await request<T>("GET", url);
}

export async function post<T = unknown>(url: string, body?: object) {
  return await request<T>("POST", url, body);
}

async function request<T>(method: string, url: string, body?: object) {
  const requestUrl = `${apiBaseUrl}${url}`;
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  let response: Response;
  try {
    response = await fetch(
      requestUrl,
      requestOptions,
    );
  } catch (e) {
    throw new RequestError(`Unable to make request to: ${requestUrl}`);
  }

  if (response.status >= 200 && response.status < 300) {
    let data = null;

    try {
      data = await response.json();
    } catch (e) {}

    return {
      status: response.status,
      data: data as T,
    };
  }

  let data: unknown = null;

  try {
    data = await response.json();
  }
  catch (e) {}

  throw new RequestError(`Request failed with status code ${response.status}`, {
    status: response.status,
    data,
  });
}

export interface ErrorResponse {
  status: number;
  data: unknown;
}

export class RequestError extends Error {
  name = 'RequestError';
  response?: ErrorResponse;

  constructor(message: string, response?: ErrorResponse) {
    super(message);
    this.response = response;
  }
}
