import "./app.css";
import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";

Sentry.init({
  dsn: "https://145c06dd5f1d7ef1b733c05f8be0476b@o4505552851763200.ingest.us.sentry.io/4507426588524544",
  integrations: [],
});

const app = new App({
  target: document.getElementById("app")!,
});

export default app;

