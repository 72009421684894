<div class="logo center">
  <img src="logo.png" alt="24Food logo" />
</div>

<style lang="scss">
  .logo {
    text-align: center;

    img {
      height: 45px;
    }
  }
</style>
