<script lang="ts">
  import Button from "./button.svelte";
  import GroceryBag from "./grocery-bag.svelte";
  import Loader from "./loader.svelte";
  import Title from "./title.svelte";

  export let onCancel: () => void;
</script>

<div>
  <GroceryBag />
  <div class="content">
    <Title>Vänligen vänta</Title>
    <div class="loader-container">
      <Loader />
    </div>
    <div class="instructions">
      <p>Inväntar svar från BankID...</p>
    </div>
  </div>
  <Button onClick={onCancel} type="secondary" label="Avbryt" />
</div>

<style lang="scss">
  .content {
    margin-top: 30px;
  }

  .loader-container {
    padding: 20px;
  }

  .instructions {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
  }
</style>
