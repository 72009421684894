<script lang="ts">
  import Title from "./title.svelte";
  import Button from "./button.svelte";
  import GroceryBag from "./grocery-bag.svelte";
  import Message from "./message.svelte";
  import type { Store } from "../types";
  import { getDistanceFromLatLonInM } from "../utils/distance";

  export let store: Store | null;
  export let position: GeolocationPosition | null;
  export let isLoadingInitialLocation: boolean;
  export let isLoading: boolean;
  export let clickButton: () => void;
</script>

<div id="start">
  <GroceryBag />
  <div class="content">
    <Title>Verifiera dig!</Title>
    <div class="instructions">
      <p>Tryck på knappen nedan för att starta BankID och öppna dörren.</p>
    </div>
  </div>
  {#if isLoadingInitialLocation}
    <Button label="Hämtar plats..." isLoading={true} className="bank-id-button">
      <img
        slot="icon"
        class="bank-id-logo"
        src="bankid.svg"
        alt="BankID logo"
      />
    </Button>
  {:else}
    <Button
      label={isLoading ? "Laddar..." : "Öppna BankID"}
      {isLoading}
      className="bank-id-button"
      onClick={clickButton}
    >
      <img
        slot="icon"
        class="bank-id-logo"
        src="bankid.svg"
        alt="BankID logo"
      />
    </Button>
  {/if}
  {#if store && position && getDistanceFromLatLonInM(position.coords.latitude, position.coords.longitude, store.latitude, store.longitude) > 300}
    <Message
      type="error"
      title="Långt ifrån butiken"
      message="Det ser ut som att du är långt ifrån butiken. För att öppna butiken behöver du vara vid den. Ibland kan det kan ta några sekunder innan din exakta plats är hittad."
    />
  {/if}
  {#if position && position.coords.accuracy > 300}
    <Message
      type="warning"
      title="Låg platsprecision"
      message="Precision på platsinformationen är låg. Detta kan medförra att vi inte kan öppna dörren."
    />
  {/if}
</div>

<style lang="scss">
  .content {
    margin-top: 30px;
  }

  .bank-id-button {
    margin-top: 40px;
  }

  .bank-id-logo {
    height: 50px;
  }

  .instructions {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
  }
</style>
