<script lang="ts">
  import Button from "./button.svelte";
  import ResultIcon from "./result-icon.svelte";
  import Title from "./title.svelte";

  export let onTryAgain: () => void;
  export let title: string;
  export let message: string;
</script>

<div>
  <ResultIcon type="error" />
  <div>
    <Title>{title}</Title>
    <div class="instructions">
      <p>{message}</p>
    </div>
  </div>
  <Button onClick={onTryAgain} label="Försök igen" />
</div>

<style lang="scss">
  .instructions {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
  }
</style>
