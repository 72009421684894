<script lang="ts">
  export let type: "success" | "error";
</script>

<div class="container">
  {#if type === "success"}
    <img class="icon" src="checkmark-circle.svg" alt="Checkmark" />
  {/if}
  {#if type === "error"}
    <img class="icon" src="alert-circle.svg" alt="Alert" />
  {/if}
</div>

<style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    text-align: center;
    margin-top: 30px;
  }

  .icon {
    height: 100%;
    z-index: 10;
  }
</style>
