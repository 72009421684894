<script lang="ts">
  import Button from "./button.svelte";
  import LocationIcon from "../assets/location-icon.svelte";

  export let open = false;
  export let onCancel: () => void = () => undefined;
  export let onAccept: () => void = () => undefined;
</script>

{#if open}
  <div class="geolocation-modal">
    <div class="geolocation-modal__content">
      <LocationIcon />
      <h1 class="geolocation-modal__title">Platsinformation</h1>
      <p class="geolocation-modal__text">
        För att låsa upp butiken behöver du dela din platsinformation. Vi
        behöver den informationen för att veta att du står vid butiken.
      </p>
      <Button onClick={onAccept} label="Dela plats" />
      <button
        on:click={onCancel}
        id="geolocation-modal-cancel"
        class="btn-text geolocation-modal__cancel-button">Avbryt</button
      >
    </div>
  </div>
{/if}

<style lang="scss">
  .geolocation-modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(34, 32, 32, 0.75);
    z-index: 100;
    align-items: center;
    justify-content: center;
  }

  .geolocation-modal__content {
    background: #fff;
    max-width: 90%;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .geolocation-modal__title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .geolocation-modal__text {
    margin-bottom: 30px;
  }

  .geolocation-modal__cancel-button {
    margin-top: 10px;
  }

  .btn-text {
    color: #333;
    border-radius: 10px;
    border: none;
    background: none;
    padding: 12px 20px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "LatoBold", Arial, sans-serif;
    position: relative;
  }
</style>
