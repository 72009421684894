<script lang="ts">
  import ResultIcon from "./result-icon.svelte";
  import Title from "./title.svelte";
</script>

<div>
  <ResultIcon type="success" />
  <div class="content">
    <Title>Välkommen in!</Title>
    <div class="instructions">
      <p>Dörren är upplåst.</p>
    </div>
  </div>
</div>

<style lang="scss">
  .content {
    margin-top: 30px;
  }

  .instructions {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
  }
</style>
