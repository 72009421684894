<script lang="ts">
</script>

<h1 class="title"><slot /></h1>

<style lang="scss">
	.title {
		color: #db421a;
		text-align: center;
		font-size: 48px;
	}
</style>
