<div class="container">
  <img class="icon" src="grocery-bag.svg" alt="Grocery bag" />
  <div class="circle"></div>
</div>

<style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
    text-align: center;
    margin-top: 30px;
  }

  .icon {
    height: 100%;
    z-index: 10;
  }

  .circle {
    background-color: #da4119;
    height: 28vh;
    width: 28vh;
    border-radius: 100%;
    position: absolute;
    opacity: 0.15;
    margin-top: 40px;
  }
</style>
