import { RequestError, post } from '../http';
import type { BankIDAuthResponse } from '../types';
import { isDesktopDevice } from './device';
import { sleep } from './sleep';

export function openBankIDForDesktop(autoStartToken: string, redirect: string) {
  window.open(
    `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`,
    "_self",
  );  
}

export function openBankIDForMobile(autoStartToken: string, redirect: string) {
  window.open(
    `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${redirect}`,
    "_self",
  );
}
