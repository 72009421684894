<script lang="ts">
  export let title = "";
  export let message = "";
  export let type: "warning" | "error" = "warning";
</script>

<div class={`conatiner ${type}`}>
  <div class="title">{title}</div>
  {message}
</div>

<style lang="scss">
  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .conatiner {
    position: relative;
    font-weight: normal;
    font-family: "Lato", sans-serif;
    padding: 10px;
    padding-left: 20px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 10px;
      display: block;
    }

    &.warning {
      &::before {
        background: #facc15;
      }
      background: #fef9c3;
    }

    &.error {
      &::before {
        background: #ef4444;
      }
      background: #fee2e2;
    }
  }
</style>
