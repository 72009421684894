<script lang="ts">
  import Loader from "./loader.svelte";

  export let label: string | null = null;
  export let className: string | null = null;
  export let onClick: (() => void) | null = null;
  export let type: "primary" | "secondary" = "primary";
  export let disabled = false;
  export let isLoading = false;
</script>

<button
  on:click={onClick}
  class={`${type} ${className}`}
  disabled={disabled || isLoading}
>
  {label}
  <div class="icon">
    {#if isLoading}
      <Loader size="sm" />
    {:else}
      <slot name="icon" />
    {/if}
  </div>
</button>

<style lang="scss">
  .primary {
    position: relative;
    background-color: #db421a;
    color: white;
    border: 2px solid #da4119;
    border-radius: 10px;
    padding: 0px 20px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
    font-family: "Lato", Arial, sans-serif;

    &:disabled {
      background-color: rgba(219, 66, 26, 0.47) !important;
      border-color: rgba(219, 66, 26, 0.47) !important;
      cursor: not-allowed;
    }
  }

  .secondary {
    position: relative;
    background-color: #333333;
    color: white;
    border: 2px solid #333333;
    border-radius: 10px;
    padding: 12px 20px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 40px;
    width: 100%;
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
    font-family: "Lato", Arial, sans-serif;
  }

  .icon {
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
