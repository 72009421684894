<script lang="ts">
  import Footer from "./ui/footer.svelte";
  import GeolocationModal from "./ui/geolocation-modal.svelte";
  import { isDesktopDevice } from "./utils/device";
  import { openBankIDForDesktop, openBankIDForMobile } from "./utils/bank-id";
  import * as Sentry from "@sentry/svelte";
  import { isGuid } from "./utils/guid";
  import { RequestError, get, post } from "./http";
  import { sleep } from "./utils/sleep";
  import {
    hasGeoLocationPermission,
    geoLocationWatcher,
    geoLocationPermissionListener,
  } from "./utils/geolocation";
  import type { BankIDAuthResponse, Store } from "./types";
  import Header from "./ui/header.svelte";
  import StatusWaiting from "./ui/status-waiting.svelte";
  import StatusError from "./ui/status-error.svelte";
  import StatusSuccess from "./ui/status-success.svelte";
  import StatusStart from "./ui/status-start.svelte";
  import InvalidStoreGuidMessage from "./ui/invalid-store-guid-message.svelte";

  let store: Store | null = null;
  let stopFetching = false;
  let storeGuid: string | null = null;
  let status: "start" | "waiting" | "error" | "success" = "start";
  let error = {
    title: "Något gick fel",
    message:
      "Ett oväntat fel uppstod och vi kunde inte slutföra din begäran. Vänligen försök igen. Om du fortsätter att uppleva problem, kontakta vår supportavdelning för hjälp.",
  };
  let geolocationModalOpen = false;
  let showInvalidStoreGUIDMessage = false;
  let position: GeolocationPosition | null = null;
  let isLoadingInitialLocation = false;
  let isFetchingAuthAutoStartToken = false;
  let unsubscribeGeoLocationWatcher: null | (() => void) = null;

  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  storeGuid = searchParams.get("store");

  if (storeGuid && isGuid(storeGuid)) {
    get<Store>(`/stores/${storeGuid}`).then(({ data }) => {
      store = data;
    });
  } else {
    showInvalidStoreGUIDMessage = true;
  }

  hasGeoLocationPermission().then((hasPermission) => {
    if (hasPermission) {
      startGeoLocationWatcher();
    } else {
      geolocationModalOpen = true;
    }
  });

  geoLocationPermissionListener((permissionState) => {
    if (permissionState === "granted") {
      startGeoLocationWatcher();
    }
  });

  function setStatusError(title: string, message: string) {
    status = "error";
    error = {
      title,
      message,
    };
  }

  function startGeoLocationWatcher() {
    if (unsubscribeGeoLocationWatcher) {
      unsubscribeGeoLocationWatcher();
    }

    isLoadingInitialLocation = true;

    unsubscribeGeoLocationWatcher = geoLocationWatcher(
      (newPosition) => {
        isLoadingInitialLocation = false;
        position = newPosition;
      },
      (error, errorMessage) => {
        isLoadingInitialLocation = false;
        alert(errorMessage);
        Sentry.captureException(error);
      },
    );
  }

  async function signWithBankID() {
    if (!position) {
      geolocationModalOpen = true;
      return;
    }

    stopFetching = false;
    fetchAuth();
  }

  function fetchAuth() {
    isFetchingAuthAutoStartToken = true;
    post<BankIDAuthResponse>("/auth/bankId/auth")
      .then(({ data }) => {
        const redirect = "null";

        if (isDesktopDevice) {
          openBankIDForDesktop(data.autoStartToken, redirect);
        } else {
          openBankIDForMobile(data.autoStartToken, redirect);
        }

        fetchCollectOpenDoor(data.orderRef);

        status = "waiting";
      })
      .catch((e) => {
        setStatusError(
          "Något gick fel",
          "Ett oväntat fel uppstod och vi kunde inte slutföra din begäran. Vänligen försök igen. Om du fortsätter att uppleva problem, kontakta vår supportavdelning för hjälp.",
        );
        Sentry.captureException(e);
      })
      .finally(() => {
        isFetchingAuthAutoStartToken = false;
      });
  }

  async function fetchCollectOpenDoor(orderRef: string) {
    const body = {
      orderRef: orderRef,
      storeGuid: storeGuid,
      latitude: position?.coords.latitude,
      longitude: position?.coords.longitude,
    };

    try {
      const response = await post("/auth/bankId/collect/open-door", body);
      if (response.status === 204) {
        status = "success";
      } else {
        if (!stopFetching) {
          await sleep(1000);
          await fetchCollectOpenDoor(orderRef);
        }
      }
    } catch (e) {
      status = "error";

      if (e instanceof RequestError) {
        if (
          e.response?.data && // Type check
          typeof e.response.data === "object" && // Type check
          "type" in e.response.data && // Type check
          typeof e.response.data.type === "string" && // Type check
          e.response.data.type === "TOO_FAR_AWAY"
        ) {
          setStatusError(
            "För långt ifrån butiken",
            "För att öppna butiken måste du vara vid den.",
          );
          return;
        }
      }

      setStatusError(
        "Något gick fel",
        "Ett oväntat fel uppstod och vi kunde inte slutföra din begäran. Vänligen försök igen. Om du fortsätter att uppleva problem, kontakta vår supportavdelning för hjälp.",
      );
      Sentry.captureException(e);
    }
  }

  function cancel() {
    stopFetching = true;
    status = "start";
  }
</script>

<main class="main">
  <Header />
  <div class="content-container">
    {#if showInvalidStoreGUIDMessage}
      <InvalidStoreGuidMessage />
    {:else}
      {#if status === "start"}
        <StatusStart
          {store}
          {isLoadingInitialLocation}
          isLoading={isFetchingAuthAutoStartToken}
          clickButton={signWithBankID}
          {position}
        />
      {/if}
      {#if status === "waiting"}
        <StatusWaiting onCancel={cancel} />
      {/if}
      {#if status === "error"}
        <StatusError
          onTryAgain={() => (status = "start")}
          title={error.title}
          message={error.message}
        />
      {/if}
      {#if status === "success"}
        <StatusSuccess />
      {/if}
    {/if}
  </div>
  <Footer />
  <GeolocationModal
    open={geolocationModalOpen}
    onAccept={() => {
      geolocationModalOpen = false;
      startGeoLocationWatcher();
    }}
    onCancel={() => (geolocationModalOpen = false)}
  />
</main>

<style lang="scss">
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .content-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
</style>
